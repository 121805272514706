<template>
  <div class="card mt-4 ml-5 mr-5" style="min-height: 70vh;">
    <b-tabs :animated="false" v-model="selectedTab">
      <b-tab-item :label="t('componentNames.questions')" icon="database">
        <QuestionsList :refreshTrigger="refreshTrigger"></QuestionsList>
      </b-tab-item>
      <b-tab-item :label="t('question.new')" icon="database-plus">
        <QuestionCreator
          @addedQuestion="addedQuestion"
          :cleanForm="true"
        ></QuestionCreator>
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import Commons from "@/mixins/commons";
import QuestionCreator from "./QuestionCreator";
import QuestionsList from "./QuestionsList";

export default {
  name: "QuestionsController",
  mixins: [Commons],
  components: { QuestionCreator, QuestionsList },

  data: function () {
    return {
      refreshTrigger: 0,
      selectedTab: 0,
    };
  },

  mounted() {},

  methods: {
    addedQuestion() {
      this.refreshTrigger++;
      this.selectedTab = 0;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
