<template>
  <div class="home">
    <Question />
  </div>
</template>

<script>
// @ is an alias to /src
import Question from '@/components/question/QuestionsController'

export default {
  name: 'QuestionsView',
  components: {
      Question
  }
}
</script>