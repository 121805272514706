<template>
  <div v-if="localQuestion">
    <header class="card-header skyColor mb-5">
      <p class="subtitle card-header-title pl-5 pt-4 pb-4 mb-0">{{t('componentNames.questionCreator')}}</p>
      <UserFeedback :target="t('componentNames.questionCreator')" class="end mr-4 mt-1"></UserFeedback>
    </header>

    <ValidationObserver ref="observer" v-slot="{ invalid }">
      <form>
        <div class="columns">
          <div class="column">
            <b-field>
              <ValidationProvider rules="required" v-slot="{ errors, valid }">
                <b-field
                  :label="t('question.title')"
                  label-position="on-border"
                  :type="{ 'is-danger': errors[0], 'is-success': valid }"
                  :message="t(errors[0])"
                >
                  <b-input type="text" v-model="localQuestion.title" :has-counter="false" required></b-input>
                </b-field>
              </ValidationProvider>
            </b-field>
            <b-field>
              <b-radio-button
                v-model="radioButton"
                native-value="0"
                type="is-primary"
                @input="localQuestion.type = 0"
              >
                <b-icon icon="format-list-checkbox"></b-icon>
                <span>{{t('question.multiChoose')}}</span>
              </b-radio-button>
              <b-radio-button
                v-model="radioButton"
                native-value="1"
                type="is-primary"
                @input="localQuestion.type = 1"
              >
                <b-icon icon="file-document-edit"></b-icon>
                <span>{{t('question.open')}}</span>
              </b-radio-button>
            </b-field>

            <b-field>
              <ValidationProvider rules="required" v-slot="{ errors, valid }">
                <b-field
                  :label="t('question.category')"
                  label-position="on-border"
                  :type="{ 'is-danger': errors[0], 'is-success': valid }"
                  :message="t(errors[0])"
                >
                  <b-select v-model="localQuestion.category" expanded>
                    <option
                      v-for="(category, index) in afmConfig.questionCategories"
                      :key="index"
                      :value="category"
                    >{{ category }}</option>
                  </b-select>
                </b-field>
              </ValidationProvider>
            </b-field>

            <b-field>
              <ValidationProvider rules="required" v-slot="{ errors, valid }">
                <b-field
                  :label="t('question.text')"
                  label-position="on-border"
                  :type="{ 'is-danger': errors[0], 'is-success': valid }"
                  :message="t(errors[0])"
                >
                  <b-input
                    v-model="localQuestion.text"
                    type="textarea"
                    :has-counter="false"
                    required
                  ></b-input>
                </b-field>
              </ValidationProvider>
            </b-field>

            <div class="pb-4" v-if="localQuestion.type === 0">
              <b-field>
                <b-checkbox
                  v-model="localQuestion.hasSingleAnswer"
                >{{t('question.multiChooseWithOneGoodAnswer')}}</b-checkbox>
              </b-field>
              <b-field
                :label="t('question.answers')"
                :message="oneChecked"
                :type="{
                  'is-danger': oneChecked != null,
                  'is-success': oneChecked == null,
                }"
              >
                <template v-if="localQuestion.hasSingleAnswer">
                  <QuestionRadio
                    :answers="localQuestion.answers"
                    @remove-answer="removeAnswerFromQuestion"
                  />
                </template>
                <template v-else>
                  <b-field v-for="answer in localQuestion.answers" :key="answer.id">
                    <b-tooltip type="is-danger" position="is-top" :label="t('common.remove')">
                      <b-button
                        @click="removeAnswerFromQuestion(answer)"
                        type="is-text"
                        size="is-small"
                      >
                        <b-icon type="is-danger" class="pt-2" icon="delete-forever-outline"></b-icon>
                      </b-button>
                    </b-tooltip>
                    <b-checkbox
                      name="option"
                      type="checkbox"
                      v-model="answer.isCorrect"
                      :checked="answer.isCorrect"
                      required
                      expanded
                    >{{ answer.text }}</b-checkbox>
                  </b-field>
                </template>
              </b-field>
              <b-field :label="t('question.addNewAnswer')" label-position="on-border">
                <b-input
                  type="text"
                  v-model="newAnswer.text"
                  expanded
                  @keydown.native.enter="addAnswerToQuestion"
                ></b-input>
                <b-button
                  icon-left="plus"
                  type="is-primary"
                  @click="addAnswerToQuestion"
                >{{t('question.addAnswerToQuestion')}}</b-button>
              </b-field>
            </div>

            <b-field>
              <ValidationProvider rules="required" v-slot="{ errors, valid }">
                <b-field
                  :label="t('question.maxPoint')"
                  label-position="on-border"
                  :type="{ 'is-danger': errors[0], 'is-success': valid }"
                  :message="t(errors[0])"
                >
                  <b-numberinput
                    expanded
                    controls-position="compact"
                    controls-alignment="right"
                    v-model="localQuestion.maxPoints"
                    min="0"
                  />
                </b-field>
              </ValidationProvider>
            </b-field>
          </div>
          <div class="column">
            <b-field :label="t('question.image')" label-position="on-border">
              <b-select v-model="localQuestion.image" expanded>
                <option value>{{t('common.noData')}}</option>
                <option
                  v-for="image in myFiles"
                  :key="image.id"
                  :value="image.id"
                >{{ image.originalFileName }}</option>
              </b-select>
            </b-field>

            <template v-if="localQuestion.image">
              <b-image
                :src="`${server}/api/MediaFile/File/${localQuestion.image}`"
                ratio="16by9"
                class="mb-4"
              >
                <template #placeholder>
                  <b-skeleton class="skeleton-placeholder" height="100%"></b-skeleton>
                </template>
              </b-image>
            </template>
            <template v-else>
              <section class="section">
                <div class="notification is-light content has-text-centered">
                  <p>
                    <b-icon icon="camera-image" size="is-large"></b-icon>
                  </p>
                  <p style="word-wrap: break-word; white-space: pre-wrap">
                    {{t('question.imageInformation')}}
                  </p>
                </div>
              </section>
            </template>

            <b-field>
              <b-field :label="t('forms.tags')" label-position="on-border">
                <b-taginput
                  v-model="localQuestion.tags"
                  ellipsis
                  icon="label"
                  :placeholder="t('common.add')"
                  :aria-close-label="t('common.remove')"
                ></b-taginput>
              </b-field>
            </b-field>
          </div>
        </div>

        <!-- <b-field>
              <ValidationProvider rules="required" v-slot="{ errors, valid }">
                <b-field
                  label="Maksymalna czas dla pytania (sekundy)"
                  :type="{ 'is-danger': errors[0], 'is-success': valid }"
                  :message="t(errors[0])"
                >
                  <b-numberinput
                    controls-position="compact"
                    controls-alignment="right"
                    v-model="localQuestion.maxTime"
                    min="0"
                  />
                </b-field>
              </ValidationProvider>
        </b-field>-->

        <hr />
        <div class="buttons">
          <b-button
            v-if="question != null"
            icon-left="book-remove"
            type="is-danger"
            :loading="isLoading"
            @click="confirmRemove"
          >{{t('question.remove')}}</b-button>
          <b-button
            @click="saveQuestion"
            :loading="isLoading"
            icon-left="book-check"
            :disabled="invalid"
            type="is-success"
          >{{t('common.save')}}</b-button>
        </div>
      </form>
    </ValidationObserver>
    <b-loading v-model="isLoading" :is-full-page="false"></b-loading>
  </div>
</template>

<script>
import CommonHelpers from "@/mixins/commons";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import QuestionRadio from "./QuestionRadio";
import { Action } from "../../store/config_actions";
import TypesHelpers from "@/mixins/types";
import UserFeedback from '@/components/feedbacks/UserFeedback';
import { SERVER } from '@/store/config_apis'

export default {
  name: "QuestionCreator",
  mixins: [CommonHelpers, TypesHelpers],
  components: {
    ValidationProvider,
    ValidationObserver,
    QuestionRadio,
    UserFeedback
  },

  props: {
    question: { type: Object, required: false },
    cleanForm: { type: Boolean, required: false },
  },

  data: function () {
    return {
      localQuestion: null,
      cleanedForm: false,
      radioButton: "",
      isLoading: false,
      messageOptions: null,
      afmConfig: {},
      newQuestionCorrectedAnswers: null,
      server: SERVER,
      newAnswer: {
        id: 0,
        isCorrect: false,
        text: "",
      },
      myFiles: [],
      newQuestion: {
        type: 0,
        title: "",
        text: "",
        answers: [],
        description: "",
        category: "",
        image: "",
        version: "",
        tags: [],
        maxTime: 0,
        hasSingleAnswer: false,
        maxPoints: 0.0,
        points: 0,
      },
    };
  },

  mounted() {
    this.getAfmConfig();
    this.getMyFiles();

    if (this.question) {
      this.localQuestion = JSON.parse(JSON.stringify(this.question));
    } else {
      this.localQuestion = JSON.parse(JSON.stringify(this.newQuestion));
    }
    this.radioButton = this.localQuestion.type.toString();
    this.newAnswer.id = this.lastAnswersId + 1;
    if (this.cleanForm) {
      this.cleanedForm = this.cleanForm;
    }
  },

  watch: {},

  computed: {
    oneChecked() {
      if (this.localQuestion.type === 0) {
        if (this.localQuestion.answers.length > 0) {
          if (this.localQuestion.answers.some((option) => option.isCorrect)) {
            return null;
          }
          return this.t('question.mustSelectOneGoodAnswer');
        }
        return this.t('question.mustAddOneAnswer');
      }
      return null;
    },

    lastAnswersId() {
      var length = this.localQuestion.answers.length;
      if (length > 0) {
        return this.localQuestion.answers[length - 1].id;
      } else {
        return 0;
      }
    },
  },

  methods: {
    addAnswerToQuestion() {
      this.newAnswer.id = this.lastAnswersId + 1;
      this.localQuestion.answers.push(this.newAnswer);
      var lastIndex = this.lastAnswersId + 1;

      this.newAnswer = {
        id: lastIndex,
        isCorrect: false,
        text: "",
      };
    },

    removeAnswerFromQuestion(answer) {
      let index = this.localQuestion.answers.indexOf(answer)
      if (index > -1) {
        this.localQuestion.answers.splice(index, 1);
      }

      for (let index = 0; index < this.localQuestion.answers.length; index++) {
        const item = this.localQuestion.answers[index];
        item.id = index + 1;
      }
    },

    confirmRemove() {
      this.$buefy.dialog.confirm({
        title: this.t('question.removeCon'),
        message: this.t('question.removeConfirm'),
        onConfirm: () => this.removeQuestion(),
        type: "is-danger",
        hasIcon: true,
        cancelText: this.t('common.no'),
        confirmText: this.t('common.yes'),
      });
    },

    removeQuestion() {
      this.isLoading = true;
      this.$store
        .dispatch(Action.QUESTION_REMOVE, this.localQuestion.id)
        .then(() => {
          this.mSuccessSnack(this.t('question.removed'));
          this.$emit("updateQuestion");
          this.isLoading = false;
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
          this.isLoading = false;
        });
    },

    saveQuestion() {
      if (this.oneChecked === null) {
        if (this.question) {
          this.updateQuestion();
        } else {
          this.addNewQuestion();
        }
      }
    },

    addNewQuestion() {
      this.isLoading = true;
      this.$store
        .dispatch(Action.QUESTION_ADD, this.localQuestion)
        .then(() => {
          this.mSuccessSnack(this.t('question.added'));
          this.localQuestion = JSON.parse(JSON.stringify(this.newQuestion));
          this.$emit("addedQuestion");
          this.isLoading = false;
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
          this.isLoading = false;
        });
    },

    updateQuestion() {
      this.isLoading = true;
      this.$store
        .dispatch(Action.QUESTION_UPDATE, this.localQuestion)
        .then(() => {
          this.mSuccessSnack(this.t('common.changesSaved'));
          this.$emit("updateQuestion");
          this.isLoading = false;
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
          this.isLoading = false;
        });
    },

    /**
     * Get AFM config
     */
    getAfmConfig() {
      this.isLoading = true;
      if (this.$store.state.afmconfig.config) {
        this.afmConfig = this.$store.state.afmconfig.config;
        this.isLoading = false;
      } else {
        this.$store
          .dispatch(Action.AFM_CONFIG_GET)
          .then((payload) => {
            this.afmConfig = payload;
            this.isLoading = false;
          })
          .catch((error) => {
            this.mDangerSnack(error.toString());
            this.isLoading = false;
          });
      }
    },

    getMyFiles() {
      this.isLoading = true;
      if (this.$store.state.media.myFiles) {
        this.myFiles = this.$store.state.media.myFiles;
        this.isLoading = false;
      } else {
        this.$store
          .dispatch(Action.MEDIA_GET_MY)
          .then((payload) => {
            this.myFiles = payload;
            this.isLoading = false;
          })
          .catch((error) => {
            this.mDangerSnack(error.toString());
            this.isLoading = false;
          });
      }
    },
  },
};
</script>

<style scoped>
</style>