<template>
  <div>
    <UserFeedback :target="t('componentNames.questions')" class="end mr-4 mt-1"></UserFeedback>
    <b-field grouped group-multiline>
      
      <b-button
        :loading="isLoading"
        @click="getQuestions"
        icon-left="refresh"
        size="is-small"
        class="mb-1"
      >{{t('common.refresh')}}</b-button>
    </b-field>
    <b-table
      :data="questions"
      :show-detail-icon="showDetailIcon"
      detailed
      narrowed
      detail-key="id"
      :opened-detailed="defaultOpenedDetails"
      :loading="isLoading"
      striped
    >
      <b-table-column :custom-sort="sortByLp" :label="t('common.lp')" sortable centered v-slot="props">{{ questions != null ? questions.indexOf(props.row) + 1 : props.index + 1}}</b-table-column>
      <b-table-column field="type" :label="t('common.type')"  v-slot="props">
        <b-tooltip :label="mQuestionTypeName(props.row.type)" position="is-right">
          <b-icon :icon="mQuestionTypeIcon(props.row.type)" size="is-medium" type="is-primary"></b-icon>
        </b-tooltip>
      </b-table-column>
      <b-table-column
        field="title"
        :label="t('question.category')" 
        v-slot="props"
        searchable
        sortable
      >{{ props.row.category }}</b-table-column>
      <b-table-column
        field="title"
        :label="t('question.title')" 
        v-slot="props"
        searchable
        sortable
      >{{ props.row.title }}</b-table-column>
      <b-table-column
        field="text"
        :label="t('question.text')" 
        v-slot="props"
        searchable
        sortable
      >{{ props.row.text }}</b-table-column>
      <b-table-column field="tags" searchable sortable :label="t('forms.tags')"  v-slot="props">
        <b-taglist>
          <b-tag v-for="tag in props.row.tags" :key="tag" type="is-secondary" size="is-small">
            {{ tag }}
          </b-tag>
        </b-taglist>
      </b-table-column>
      <template slot="detail" slot-scope="props">
        <template>
          <QuestionCreator :question="props.row" @updateQuestion="getQuestions"></QuestionCreator>
        </template>
      </template>
    </b-table>
  </div>
</template>

<script>
import CommonHelpers from "@/mixins/commons";
import TypesHelpers from "@/mixins/types";
import { Action } from "../../store/config_actions";
import QuestionCreator from "./QuestionCreator";
import UserFeedback from '@/components/feedbacks/UserFeedback';

export default {
  name: "QuestionsController",
  mixins: [CommonHelpers, TypesHelpers],
  components: {
    QuestionCreator,
    UserFeedback
  },

  props: {
    refreshTrigger: { type: Number, required: true },
  },

  data: function () {
    return {
      questions: [],
      perPage: 50,
      showDetailIcon: true,
      defaultOpenedDetails: [0],
      isLoading: false,
      modifiedQuestion: false,
      newQuestion: {
        type: 0,
        title: "",
        text: "",
        answers: [],
        description: "",
        image: "",
        version: "",
        tags: [],
      },
    };
  },

  mounted() {
    this.getQuestions();
    this.radioButton = this.newQuestion.type.toString();
  },

  watch: {
    refreshTrigger() {
      this.getQuestions();
    },
  },

  computed: {},

  methods: {
    getQuestions() {
      this.isLoading = true;
      this.$store
        .dispatch(Action.QUESTION_GET_ALL)
        .then((payload) => {
          this.questions = payload;
          this.isLoading = false;
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
          this.isLoading = false;
        });
    },

    addNewQuestion(question) {
      this.isLoading = true;
      this.$store
        .dispatch(Action.QUESTION_ADD, question)
        .then((payload) => {
          this.questions.push(payload);
          this.newQuestion = {
            type: 0,
            title: "",
            text: "",
            answers: [],
            description: "",
            image: "",
            version: "",
          };
          this.isLoading = false;
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
          this.isLoading = false;
        });
    },

    confirmRemove(event, id) {
      this.$buefy.dialog.confirm({
        title: this.t('question.remove'),
        message: this.t('question.removeConfirm'),
        onConfirm: () => this.removeQuestion(id),
        type: "is-danger",
        hasIcon: true,
        cancelText: this.t('common.no'),
        confirmText: this.t('common.yes'),
      });
    },

    removeQuestion(id) {
      this.isLoading = true;
      this.$store
        .dispatch(Action.QUESTION_REMOVE, id)
        .then((payload) => {
          let removingQuestion = this.questions.find((q) => q.id === payload);
          if (removingQuestion) {
            this.questions.splice(this.questions.indexOf(removingQuestion), 1);
          }
          this.isLoading = false;
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
          this.isLoading = false;
        });
    },

    saveModification(question) {
      this.$store
        .dispatch(Action.QUESTION_UPDATE, question)
        .then(() => {
          this.mSuccessSnack(this.t('common.changesSaved'),);
          this.getQuestions();
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
          this.isLoading = false;
        });
    },

    showQuestion(event, id) {
      let containsId = this.defaultOpenedDetails.includes(id);
      if (containsId) {
        let index = this.defaultOpenedDetails.indexOf(id);
        this.defaultOpenedDetails.splice(index, 1);
      } else {
        this.defaultOpenedDetails.push(id);
      }
      this.modifiedQuestion = false;
    },

    modifyQuestion(event, id) {
      this.defaultOpenedDetails = [id];
      this.modifiedQuestion = !this.modifiedQuestion;
    },

    addAnswerToQuestion() {
      var lastIndex = this.newAnswer.id;
      lastIndex = lastIndex + 1;
      this.newQuestion.answers.push(this.newAnswer);

      this.newAnswer = {
        id: lastIndex,
        isCorrect: false,
        text: "",
      };
    },

    sortByLp(first, second, isAsc){
        if(this.questions != null){
          let indexFirst = this.questions.indexOf(first);
          let indexSecond = this.questions.indexOf(second);
          if(isAsc){
            return indexSecond - indexFirst
          }else{
            return indexFirst- indexSecond
          }
        }else{
          return 0
        }
    }
  },
};
</script>

<style scoped>
li {
  list-style-type: disc;
}
.text-danger {
  color: red;
}
.text-sucess {
  color: green;
}
div.end{
  float: right;
}
</style>