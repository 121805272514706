export default {
    data: function () {
        return {
            mClassroomTypes: [
                {
                    name: this.t('classroom.types.lecture'),//"Zajęcia",
                    id: 0,
                    examType: null,
                    iconName: "school",
                },
                {
                    name: this.t('classroom.types.examTest'),// "Egzamin Test MCQ",
                    id: 1,
                    examType: 0,
                    iconName: "format-list-numbered",
                },
                {
                    name: this.t('classroom.types.examMultimedia'),// "Egzamin Multimedia",
                    id: 2,
                    examType: 1,
                    iconName: "image",
                },
                {
                    name: this.t('classroom.types.examCombo'),//"Egzamin Test MCQ + Multimedia",
                    id: 3,
                    examType: 2,
                    iconName: "flip-horizontal"
                }
            ],
            mExamTypes: [
                {
                    name: this.t('classroom.types.examTest'), //"Egzamin Test MCQ",
                    id: 0,
                    iconName: "format-list-numbered"
                },
                {
                    name: this.t('classroom.types.examMultimedia'),//"Egzamin Multimedia",
                    id: 1,
                    iconName: "image"
                },
                {
                    name: this.t('classroom.types.examCombo'),// "Egzamin Test MCQ + Multimedia",
                    id: 2,
                    iconName: "flip-horizontal"
                }

            ],
            mQuestionTypes: [
                {
                    name: this.t('question.types.multiChoose'), //"Pytanie wielokrotnego wyboru",
                    id: 0,
                    iconName: 'format-list-checkbox'
                },
                {
                    name: this.t('question.types.open'),//"Pytanie otwarte",
                    id: 1,
                    iconName: 'file-document-edit'
                }

            ],

            mLevelCourse: [
                {
                    name: this.t('course.levels.unset'), //"Brak",
                    id: 0
                },
                {
                    name: this.t('course.levels.basic'), //"Podstawowy",
                    id: 1
                },
                {
                    name: this.t('course.levels.intermediate'),//"Średniozaawansowany",
                    id: 2
                },
                {
                    name: this.t('course.levels.advanced'), //"Zaawansowany",
                    id: 3
                }
            ]
        }
    },

    methods: {
        mGetClassroomType(id) {
            let classroom = this.mClassroomTypes.find(c => c.id == id)
            if (classroom) return classroom
            else return `??? [${id}]`
        },

        mGetClassroomTypeName(id) {
            let classroom = this.mClassroomTypes.find(c => c.id == id)
            if (classroom) return classroom.name
            else return `??? [${id}]`
        },

        mGetClassroomTypeIcon(id) {
            let classroom = this.mClassroomTypes.find(c => c.id == id)
            if (classroom) return classroom.iconName
            else return `??? [${id}]`
        },

        mGetExamType(id){
            let examType = this.mExamTypes.find(c => c.id == id)
            if (examType) return examType
            else return `??? [${id}]`
        },

        mExamTypeName(id) {
            let examType = this.mExamTypes.find(c => c.id == id)
            if (examType) return examType.name
            else return `??? [${id}]`
        },

        mExamTypeIcon(id) {
            let examType = this.mExamTypes.find(c => c.id == id)
            if (examType) return examType.iconName
            else return `??? [${id}]`
        },

        mQuestionTypeName(id) {
            let questionType = this.mQuestionTypes.find(c => c.id == id)
            if (questionType) return questionType.name
            else return `??? [${id}]`
        },

        mQuestionTypeIcon(id) {
            let questionType = this.mQuestionTypes.find(c => c.id == id)
            if (questionType) return questionType.iconName
            else return `??? [${id}]`
        },

        mGetLevelCourseName(id) {
            let courseLevel = this.mLevelCourse.find(c => c.id == id)
            if (courseLevel) return courseLevel.name
            else return `??? [${id}]`
        }
    }
}