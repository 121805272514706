<template>
  <div>
    <b-field v-for="answer in answers" :key="answer.id"> 
      <b-tooltip type="is-danger"  position="is-top" :label="t('common.remove')"><b-button type="is-text" size="is-small" @click="removeAnswer(answer)"><b-icon type="is-danger" class="pt-2" icon="delete-forever-outline"></b-icon></b-button></b-tooltip>      
      <b-radio
        name="option"
        type="radio"
        @input="answerChanged"
        :native-value="answer.id"
        v-model="tmpValue"
        required
        expanded
        >{{ answer.text }}
      </b-radio> 
          
    </b-field>
  </div>
</template>

<script>
import CommonHelpers from "@/mixins/commons";

export default {
  name: "QuestionRadio",
  mixins: [CommonHelpers],
  components: {},

  props: { answers: { type: Array, required: true } },

  data: function () {
    return {
        tmpValue: null,
    };
  },

  mounted() {
      this.setTmpValue();
  },

  watch: {
  },

  computed: {},

  methods: {
    answerChanged(value) {
      this.answers.forEach((element) => {
        if (element.id === value) {
          element.isCorrect = true;
        } else {
          element.isCorrect = false;
        }
      });
    },

    setTmpValue() {
      this.answers.forEach((element) => {
        if (element.isCorrect) {
          this.tmpValue = element.id.toString();
        }
      });
    },

    removeAnswer(answer){
      this.$emit('remove-answer',answer)
    }
  },
};
</script>

<style scoped>
</style>
